import { Radio } from 'antd';
import { useState } from 'react';
import UserGroupPermissionTable from './UserGroupPermissionTable';
import UserPermissionTable from './UserPermissionTable';

const Permissions = () => {
  const [modeType, setModeType] = useState<'users' | 'user-groups'>('users');

  return (
    <div className="container-fluid pt-4">
      <div className="d-flex justify-content-between">
        <p className="page-listing-title">Client Users</p>
      </div>
      <Radio.Group onChange={(e) => setModeType(e.target.value)} defaultValue={modeType} size="small">
        <Radio.Button value="users">Client Users</Radio.Button>
        <Radio.Button value="user-groups">Client User Groups & Permission</Radio.Button>
      </Radio.Group>

      <div className="mt-4">
        {modeType === 'users' && <UserPermissionTable />}
        {modeType === 'user-groups' && <UserGroupPermissionTable />}
      </div>
    </div>
  );
};

export default Permissions;

import AddEditBankModal from '@/components/Modals/AddEditBankModal';
import { IBank } from '@/types/app';
import { isEmployee } from '@/utils/auth';
import { Button, Card, Col, Row } from 'antd';
import { useState } from 'react';

const BankCard = ({ bank, onEditSuccess }: { bank: IBank; onEditSuccess?: () => void }) => {
  const [openEditModal, setOpenEditModal] = useState(false);

  return (
    <>
      <Card className="mb-2">
        <Row gutter={[16, 8]} className="general-information">
          <Col xs={24} className="py-3 title-tab">
            <Row>
              <p className="title flex-grow-1">Bank Details</p>
              {isEmployee() ? null : (
                <Button type="text" onClick={() => setOpenEditModal(true)}>
                  Edit
                </Button>
              )}
            </Row>
          </Col>
          <Col xs={8}>
            <p className="label">Bank Name</p>
          </Col>
          <Col xs={16} className="value">
            {bank.bankName?.name ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">Account Name</p>
          </Col>
          <Col xs={16} className="value">
            {bank.accountName ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">Account Number</p>
          </Col>
          <Col xs={16} className="value">
            {bank.accountNumber ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">Sort Code</p>
          </Col>
          <Col xs={16} className="value">
            {bank.sortCode ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">Building Society Number</p>
          </Col>
          <Col xs={16} className="value">
            {bank.buildingSocietyNumber ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">IBAN Reference</p>
          </Col>
          <Col xs={16} className="value">
            {bank.IBanReference ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">SWIFT No</p>
          </Col>
          <Col xs={16} className="value">
            {bank.SwiftNo ?? '-'}
          </Col>
          <Col xs={8}>
            <p className="label">Country</p>
          </Col>
          <Col xs={16} className="value">
            {bank.country?.name ?? '-'}
          </Col>
        </Row>
      </Card>

      <AddEditBankModal
        type="edit"
        data={bank}
        open={openEditModal}
        destroyOnClose
        onClose={() => setOpenEditModal(false)}
        onSubmitSuccess={onEditSuccess}
      />
    </>
  );
};

export default BankCard;

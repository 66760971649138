import styled from "@emotion/styled";
import { GRAY_SCALE } from "@/common/constants/ThemeConstant";
import { baseTheme, rgba } from "@/config/ThemeConfig";
import { MEDIA_QUERIES } from "@/common/constants/ThemeConstant";

const isDark = (mode: string | undefined) => {
  return mode === "dark";
};

interface INavItemProps {
  mode?: string;
}

const NavItem = styled("div")`
  display: flex;
  align-items: center;
  line-height: 1.5;
  cursor: pointer;
  padding: 0 1rem;
  color: ${(props: INavItemProps) =>
    isDark(props?.mode) ? GRAY_SCALE.GRAY : rgba(GRAY_SCALE.GRAY, 0.85)};

  @media ${MEDIA_QUERIES.MOBILE} {
    padding: 0 0.75rem;
  }

  .ant-badge {
    color: ${(props) =>
      isDark(props.mode) ? GRAY_SCALE.GRAY : rgba(GRAY_SCALE.WHITE, 0.85)};
  }

  .nav-icon {
    font-size: 1.25rem;
  }

  &:hover,
  &.ant-dropdown-open,
  &.ant-popover-open {
    > * {
      color: ${(props) =>
        isDark(props.mode) ? baseTheme.colorPrimary : GRAY_SCALE.WHITE};
    }

    .profile-text {
      color: ${(props) => GRAY_SCALE.DARK
    }
  }
`;

export default NavItem;

import api from '@/common/api';
import { FILTER_DEFAULT, SLICE_NAME } from '@/common/constants/stores';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { IAttachment, IMessage, IMessageQuery, IMessageRoom, IMessageRoomResponse, IPagination } from '@/types/app';
import { navigateTo } from '@/utils/auth';
import { openNotification } from '@/utils';
import { ROUTE_PATH } from '@/common/constants/routes';
import { uploadS3 } from '@/common/api/s3';

export interface IMessageRoomState {
  data?: IMessageRoom[];
  loading: { [key: string]: boolean };
  pagination: IPagination;
  detail?: IMessageRoom;
  totalMessagesNotSeen?: string;
  filter: IMessageQuery;
}

export const initialState: IMessageRoomState = {
  pagination: {
    total: 0,
    page: 0,
    limit: 10,
    totalPage: 0,
  },
  data: [],
  loading: {},
  totalMessagesNotSeen: '',
  filter: { status: 'active', ...FILTER_DEFAULT },
};

const getMessageRooms = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/getMessageRooms`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getMessageRooms<IMessageRoomResponse>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getMoreMessageRooms = createAsyncThunk(
  `${SLICE_NAME.MESSAGES}/getMoreMessageRooms`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getMessageRooms<IMessageRoomResponse>({
        ...query,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const getMessageRoom = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/getMessageRoom`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getMessageRoom<{ data: IMessageRoom }>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const createMessageRoom = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/createMessageRoom`,
  async (form: IMessageRoom, { rejectWithValue }) => {
    try {
      console.log('form here', form);
      console.log('form here attachment', form?.attachments);
      let attachments: IAttachment[] = [];
      if (form?.attachments && Array.isArray(form.attachments) && form.attachments.length > 0) {
        const requests = form.attachments
          .filter((attachment) => attachment instanceof File)
          .map((attachment) => uploadS3(attachment, 'document_suites'));
        const responses = await Promise.all(requests);
        attachments = responses.filter((response) => response.success).map((response) => response.data);
      }
      form = {
        ...form,
        attachments,
      };
      // if (form?.attachments && form?.attachments instanceof File) {
      //   const { success, data: attachment } = await uploadS3(form?.attachments, 'document_suites');
      //   if (success) {
      //     form = {
      //       ...form,
      //       attachments,
      //     };
      //   }
      // }
      const data = await api.createMessageRoom<{ data: IMessageRoom }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const updateMessageRoom = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/updateMessageRoom`,
  async (form: IMessageRoom, { rejectWithValue }) => {
    try {
      const data = await api.updateMessageRoom<{ data: IMessageRoom }>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const deleteMessageRoom = createAsyncThunk(`${SLICE_NAME.MESSAGES_ROOM}/deleteMessageRoom`, async (query: any, { rejectWithValue }) => {
  try {
    const data = await api.deleteMessageRoom<{ data: IMessageRoom }>(query);
    return data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

const getMessageRoomStatistics = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/getMessageRoomStatistics`,
  async (query: any | undefined, { rejectWithValue }) => {
    try {
      const data = await api.getMessageRoomStatistics<any>(query);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const markAsSeenMessageRoom = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/markAsSeenMessageRoom`,
  async (form: any, { rejectWithValue }) => {
    try {
      const data = await api.markAsSeenMessageRoom<any>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const changeStatusMessageRoom = createAsyncThunk(
  `${SLICE_NAME.MESSAGES_ROOM}/changeStatusMessageRoom`,
  async (form: any, { rejectWithValue }) => {
    try {
      const data = await api.changeStatusMessageRoom<any>(form);
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const mapFromResponse = (response: IMessageRoom) => {
  return {
    ...response,
  };
};

const messageRoomSlice = createSlice({
  name: SLICE_NAME.MESSAGES_ROOM,
  initialState: initialState,
  reducers: {
    sortChatRoomsByNewestMessage: (state, { payload }: PayloadAction<IMessage>) => {
      var newData = (state?.data || [])?.sort(function (x, y) {
        return x?.id == payload?.chatRoomID ? -1 : y.id == payload?.chatRoomID ? 1 : 0;
      });
      state.data = newData;
    },
    setFilter(state, { payload }) {
      let newFilter = { ...payload };
      Object.keys(payload)?.filter((key) => {
        if (!payload?.[key]) {
          delete newFilter?.[key];
        }
      });
      state.filter = newFilter;
    },
    resetFilter(state) {
      state.filter = initialState.filter;
    },
    resetMessageRoomDetail(state) {
      state.detail = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMessageRooms.fulfilled, (state, { payload }) => {
      state.loading[getMessageRooms.typePrefix] = false;
      state.data = payload.data.data.map((item) => mapFromResponse(item));
      state.pagination = payload.data.pagination;
    });
    builder.addCase(getMessageRooms.pending, (state, { payload }) => {
      state.loading[getMessageRooms.typePrefix] = true;
    });
    builder.addCase(getMessageRooms.rejected, (state, { payload }) => {
      state.loading[getMessageRooms.typePrefix] = false;
    });
    builder.addCase(getMessageRoom.fulfilled, (state, { payload }) => {
      state.loading[getMessageRoom.typePrefix] = false;
      state.detail = mapFromResponse(payload.data.data);
    });
    builder.addCase(getMessageRoom.pending, (state, { payload }) => {
      state.loading[getMessageRoom.typePrefix] = true;
    });
    builder.addCase(getMessageRoom.rejected, (state, { payload }) => {
      state.loading[getMessageRoom.typePrefix] = false;
    });
    builder.addCase(createMessageRoom.fulfilled, (state, { payload }) => {
      state.loading[createMessageRoom.typePrefix] = false;
      openNotification('success', 'Message room created successfully!');
    });
    builder.addCase(createMessageRoom.pending, (state, { payload }) => {
      state.loading[createMessageRoom.typePrefix] = true;
    });
    builder.addCase(createMessageRoom.rejected, (state, { payload }) => {
      state.loading[createMessageRoom.typePrefix] = false;
      openNotification('error', 'Create message room failed!');
    });
    builder.addCase(updateMessageRoom.fulfilled, (state, { payload }) => {
      state.loading[updateMessageRoom.typePrefix] = false;
      openNotification('success', 'Update successfully!');
    });
    builder.addCase(updateMessageRoom.pending, (state, { payload }) => {
      state.loading[updateMessageRoom.typePrefix] = true;
    });
    builder.addCase(updateMessageRoom.rejected, (state, { payload }) => {
      state.loading[updateMessageRoom.typePrefix] = false;
    });
    builder.addCase(deleteMessageRoom.fulfilled, (state, { payload }) => {
      openNotification('success', `${payload?.data?.data?.subject} delete successfully!`);
      state.loading[deleteMessageRoom.typePrefix] = false;
    });
    builder.addCase(deleteMessageRoom.pending, (state, { payload }) => {
      state.loading[deleteMessageRoom.typePrefix] = true;
    });
    builder.addCase(deleteMessageRoom.rejected, (state, { payload }) => {
      openNotification('error', 'Delete failed!');
      state.loading[deleteMessageRoom.typePrefix] = false;
    });

    builder.addCase(getMoreMessageRooms.fulfilled, (state, { payload }) => {
      state.loading[getMoreMessageRooms.typePrefix] = false;

      if (payload.data.pagination.page !== 1) {
        state.data?.push(...payload.data.data.map((item) => mapFromResponse(item)));
      } else {
        state.data = payload.data.data.map((item) => mapFromResponse(item));
      }

      state.pagination = payload.data.pagination;
    });
    builder.addCase(getMoreMessageRooms.pending, (state, { payload }) => {
      state.loading[getMoreMessageRooms.typePrefix] = true;
    });
    builder.addCase(getMoreMessageRooms.rejected, (state, { payload }) => {
      state.loading[getMoreMessageRooms.typePrefix] = false;
    });
    builder.addCase(getMessageRoomStatistics.fulfilled, (state, { payload }) => {
      state.loading[getMessageRoomStatistics.typePrefix] = false;
      state.totalMessagesNotSeen = payload.data.data.totalMessagesNotSeen;
    });
    builder.addCase(getMessageRoomStatistics.pending, (state, { payload }) => {
      state.loading[getMessageRoomStatistics.typePrefix] = true;
    });
    builder.addCase(getMessageRoomStatistics.rejected, (state, { payload }) => {
      state.loading[getMessageRoomStatistics.typePrefix] = false;
    });
    builder.addCase(markAsSeenMessageRoom.fulfilled, (state, { payload }) => {
      state.loading[markAsSeenMessageRoom.typePrefix] = false;
      // openNotification("success", "Update successfully!");
    });
    builder.addCase(markAsSeenMessageRoom.pending, (state, { payload }) => {
      state.loading[markAsSeenMessageRoom.typePrefix] = true;
    });
    builder.addCase(markAsSeenMessageRoom.rejected, (state, { payload }) => {
      state.loading[markAsSeenMessageRoom.typePrefix] = false;
    });
    builder.addCase(changeStatusMessageRoom.fulfilled, (state, { payload }) => {
      state.loading[changeStatusMessageRoom.typePrefix] = false;
      if(payload?.data?.data?.status==="active") openNotification("success", "Reactive conversation successfully!");
      else openNotification("success", "Close conversation successfully!");
    });
    builder.addCase(changeStatusMessageRoom.pending, (state, { payload }) => {
      state.loading[changeStatusMessageRoom.typePrefix] = true;
    });
    builder.addCase(changeStatusMessageRoom.rejected, (state, { payload }) => {
      state.loading[changeStatusMessageRoom.typePrefix] = false;
    });
  },
});

export const messageRoomReducer = messageRoomSlice.reducer;
export const messageRoomCaseReducers = messageRoomSlice.caseReducers;
export const messageRoomActions = {
  ...messageRoomSlice.actions,
  getMessageRooms,
  getMoreMessageRooms,
  getMessageRoom,
  createMessageRoom,
  updateMessageRoom,
  deleteMessageRoom,
  getMessageRoomStatistics,
  markAsSeenMessageRoom,
  changeStatusMessageRoom,
};

import { common } from './common';

export const local = {
  ...common,
  REACT_APP_BUILD_ENV: 'local',
  BASE_URL: 'http://localhost:8080',
  WEBSOCKET_BASE_URL: 'ws://chat.portal.localhost',
  STORE_URL: 'https://dy6pb5hiu8i7r.cloudfront.net',
  BUCKET_URL: 'https://eor-portal-dev.s3.eu-west-2.amazonaws.com',
};

import { useDispatch, useSelector } from '@/stores';
import { messageRoomActions } from '@/stores/messageRooms';
import { IMessageRoom, UserRole } from '@/types/app';
import { messageActions } from '@/stores/messages';

import { constantsToOptions } from '@/utils';
import { Button, Form, Input, InputRef, Modal, Select } from 'antd';
import SearchUsers from '../SearchUsers';
import { ReactComponent as UploadIcon } from '@/assets/images/new-icons/upload-icon.svg';
import { ChangeEvent, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomUpload from '@/pages/authenticated/Messages/customUpload';
import { UploadFile } from 'antd/lib/upload';
import { UserRole as UserRoleEnum } from '@/common/constants/enums';

interface IProps {
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
  hideTo?: boolean;
  role?: UserRole;
}

const NewMessageModal = (props: IProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { constants } = useSelector((state) => state.app);
  const { loading, filter } = useSelector((state) => state.messageRooms);

  const dispatch = useDispatch();
  const { isOpen, setIsOpen, hideTo, role } = props;
  const [form] = Form.useForm();

  const initialState: IMessageRoom = {
    id: '',
  };

  const handleCloseModal = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleSubmitForm = async (e: IMessageRoom) => {
    const result = transformBeforeSubmit(e);
    await dispatch(messageRoomActions.createMessageRoom(result));
    await dispatch(messageRoomActions.getMessageRooms({...filter}));
    form.resetFields();
    handleCloseModal();
    setFileList([]);
  };

  const getSuccessFileList = () => {
    return fileList
      .filter((file) => file?.status === 'done')
      .map((file) => (file.originFileObj && file.originFileObj instanceof File ? file.originFileObj : null))
      .filter((file) => file !== null);
  };

  const transformBeforeSubmit = (e: any) => {
    let newValues = { ...e, attachments: getSuccessFileList() };
    if (role && role === 'client') {
      newValues = { ...newValues, forRole: 'company' };
    }
    return newValues;
  };

  return (
    <Modal title="New Message" open={isOpen} onOk={form.submit} okButtonProps={{disabled: loading[messageRoomActions.createMessageRoom.typePrefix]}} okText="Create" onCancel={() => handleCloseModal()} width={600} centered>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        initialValues={initialState}
        onFinish={handleSubmitForm}
        form={form}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Item label="Department" name="department" rules={[{ required: true, message: 'This field is required!' }]}>
              <Select className="w-100" placeholder="Select department" options={constantsToOptions(constants?.departments || [])} />
            </Form.Item>
          </div>
          {!hideTo && (
            <div className="col-md-12">
              <Form.Item label="To" name="participantID" rules={[{ required: true, message: 'This field is required!' }]}>
                <SearchUsers
                  showSuggest
                  // user={item?.user}
                  onSelect={(value) => {
                    form.setFieldValue('participantID', value);
                  }}
                  roles={[UserRoleEnum.client]}
                />
              </Form.Item>
            </div>
          )}
          <div className="col-md-12">
            <Form.Item label="Subject" name="subject" rules={[{ required: true, message: 'This field is required!' }]}>
              <Input className="w-100" placeholder="Enter subject" required maxLength={128} />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item label="Message" name="message" >
              <Input.TextArea rows={2} className="w-100" placeholder="Enter message" required maxLength={128} />
            </Form.Item>
          </div>
          <div className="col-md-12">
            <Form.Item
              label="Attachment"
              name="attachment"
              // rules={[{ required: true, message: "This field is required!" }]}
            >
              <CustomUpload condition="create" fileList={fileList} setFileList={setFileList} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default NewMessageModal;

import { IDocument, IDocumentQuery, IEmployee } from '@/types/app';
import { Button, Form, Grid, Input, Modal, Space, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { FileTwoTone, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { downloadFileFromURL, getBreakPoint, matchValueToLabel, openNotification } from '@/utils';
import { dateFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useEffect, useState } from 'react';
import UploadInvoiceModal from '@/components/Modals/UploadInvoiceModal';
import dayjs from 'dayjs';
import { enumOptions, UserRole } from '@/common/constants/enums';
import { useDispatch, useSelector } from '@/stores';
import { invoicesActions } from '@/stores/invoices';

import Paragraph from 'antd/es/typography/Paragraph';
import { DownloadIcon, EyeIcon, SearchIcon } from '@/components/common/IconComponents';
import { ReactComponent as IconX } from '@/assets/images/new-icons/x-icon.svg';
import { SorterResult } from 'antd/es/table/interface';

import { importsActions } from '@/stores/imports';
import UploadFile from './UploadFile';
import { FILTER_DEFAULT } from '@/common/constants/stores';

const { useBreakpoint } = Grid;

const Import = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { constants, countries } = useSelector((state) => state?.app);
  const {
    data,
    pagination,
    // paginationSubData
    filter,
    loading,
    detail,
    reviews,
    link,
  } = useSelector((state) => state?.imports);
  // const { data, subPagination, subFilter, subLoading } = useSelector((state) => state?.subImports);
  const dispatch = useDispatch();
  const [openTable, setOpenTable] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<any>([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [idOpen, setIdOpen] = useState<any>(null);
  const [seeAllContentList, setSeeAllContentList] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(importsActions.getImportTemplate());
    return ()=>{
      dispatch(importsActions.resetFilter());
    }
  }, []);

  useEffect(() => {
    // dispatch(invoicesActions.getDocuments(filter));
    dispatch(importsActions.getDocuments(filter));
  }, [filter]);

  useEffect(() => {
    console.log('ID is opening', idOpen);
    if (idOpen) {
      dispatch(importsActions.getDocument({ id: idOpen }));
      dispatch(
        importsActions.getDocumentReview({
          id: idOpen,
        }),
      );
    }
  }, [openTable, idOpen]);

  const onChangeFilter = (value: IDocumentQuery) => {
    dispatch(
      importsActions.setFilter({
        ...filter,
        ...value,
      }),
    );
  };

  const subColumns: ColumnsType<IEmployee> = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },
    {
      title: 'Forename',
      dataIndex: 'forename',
      key: 'forename',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },
    {
      title: 'Surname',
      dataIndex: 'surname',
      key: 'surname',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },

    {
      title: 'DOB',
      dataIndex: 'dob',
      key: 'dob',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{dayjs(item).format(dateFormat)}</Space>,
    },
    {
      title: 'Nationality',
      dataIndex: 'employeeNationality',
      key: 'nationality',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item?.name ? item?.name : ''}</Space>,
    },

    {
      title: 'Marital Status',
      dataIndex: 'maritalStatus',
      key: 'maritalStatus',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{matchValueToLabel(constants?.maritalStatuses || [], item)}</Space>,
    },

    {
      title: 'Country of Hire',
      dataIndex: 'countryOfHire',
      key: 'countryOfHire',
      width: 200,
      sorter: true,
      render: (item) => (item?.name ? <Space>{item.name}</Space> : ''),
    },

    {
      title: 'Job Title',
      dataIndex: 'jobTitle',
      key: 'jobTitle',
      width: 200,
      sorter: true,
      render: (item) => item && <Tag>{item}</Tag>,
    },

    {
      title: 'Work Type',
      dataIndex: 'workType',
      key: 'workType',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{matchValueToLabel(constants?.workTypes || [], item)}</Space>,
    },

    {
      title: 'Job Description',
      dataIndex: 'jobDescription',
      key: 'jobDescription',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },

    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },
    {
      title: 'Pay Rate',
      dataIndex: 'payRate',
      key: 'payRate',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{item}</Space>,
    },
    {
      title: 'Visa Required',
      dataIndex: 'needVisa',
      key: 'visaRequired',
      width: 200,
      sorter: true,
      render: (item) => <Space>{item === true ? 'Yes' : item === false ? 'No' : ''}</Space>,
    },

    {
      title: 'Insurance Required',
      dataIndex: 'requireHealthInsurance',
      key: 'requireHealthInsurance',
      width: 200,
      sorter: true,
      render: (item) => <Space>{item === true ? 'Yes' : item === false ? 'No' : ''}</Space>,
    },

    {
      title: 'Contract Type',
      dataIndex: 'contractType',
      key: 'contractRequired',
      width: 200,
      sorter: true,
      render: (item) => item && <Tag>{matchValueToLabel(constants?.contactTypes || [], item)}</Tag>,
    },

    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      sorter: true,
      render: (item) => item && <Space>{dayjs.unix(item).format(dateFormat)}</Space>,
    },
  ];

  const errorColumns: ColumnsType<any> = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      width: 100,
    },
    {
      title: 'Missing Fields',
      dataIndex: 'fieldsMissing',
      key: 'fieldsMissing',
      width: 400,
      render: (fieldsMissing) => fieldsMissing?.map((item: string, index: number) =>
        fieldsMissing?.length - 1 === index ? item : item + ', '),
    },
  ];

  const columns: ColumnsType<IDocument> = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'left',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Space>
          <Tooltip title="View Imported Data" trigger={['click','hover']}>
            <Button
              type="text"
              size="small"
              onClick={() => {
                setIdOpen(record?.id);
                // window.open(record?.attachment?.fileURL);
                setOpenTable(true);
              }}
            >
              <EyeIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              type="text"
              size="small"
              onClick={() => {
                downloadFileFromURL(record?.attachment?.fileURL, record?.attachment?.metadata?.name);
              }}
            >
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Space>
      ),
    },
    {
      title: 'Document Name',
      dataIndex: ['attachment', 'metadata', 'name'],
      key: 'documentName',
      width: 200,
      sorter: true,
      render: (value, record) => {
        return (
          <>
            {value ? (
              <Paragraph
                className="mb-0"
                ellipsis={{
                  rows: 1,
                  // expandable: false,
                  // tooltip: value,
                }}
                title={value}
              >
                {value}
              </Paragraph>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: true,
      render: (val) => val && <div>{dayjs.unix(val).format(dateFormat)}</div>,
    },
  ];

  const handleOpenModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    onChangeFilter({ keyword });
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    dispatch(
      importsActions.getDocuments({
        ...filter,
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
      }),
    );
  };

  const handleSortAndPaginationChangeSubData = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDocument> | SorterResult<IDocument>[],
  ) => {
    dispatch(
      importsActions.getDocumentReview({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        ...filter,
        id: idOpen,
      }),
    );
  };

  const handleSubmitDocument = async (values: IDocument) => {
    let newValues: IDocument = { ...values, type: 'invoice' };
    dispatch(importsActions.createDocument(newValues))
      ?.unwrap()
      ?.then((res: any) => {
        console.log('res', res)
        if(res?.success){
          if (res?.data?.data?.errors?.length) {
            setIsErrorModalOpen(true);
            setErrorData(res?.data?.data?.errors);
          } else {
            openNotification('success', 'Uploaded document successfully!');
            dispatch(importsActions.getDocuments(filter));
          }
        }
        // dispatch(importActions?.onCloseModalImport());
        // dispatch(importActions.getImports({}));
      })
      .catch((res) => {
        console.log('error', res?.message);
      });
    setIsModalOpen(false);
  };

  const handleDownloadTemplate = () => {
    console.log('Link', link);

    window.open(link, 'template.xlsx');
  };

  return (
    <div className="container-fluid pt-4">
      <div className="d-flex justify-content-between">
        <p className="page-listing-title">Imports</p>
      </div>
      <div className="bg-white rounded">
        <div className="card-header d-flex justify-content-between flex-column flex-md-row align-items-stretch">
          {!openTable && (
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          {!openTable && userInfo?.role === UserRole.client && (
            <Space className="justify-content-end">
              <Button
                className="d-flex align-items-center mr-2"
                type="primary"
                onClick={handleDownloadTemplate}
              >
                Download Template
              </Button>
              <Button className="d-flex align-items-center" type="primary" onClick={() => handleOpenModal(true)}>
                <PlusOutlined />
                Import
              </Button>
            </Space>
          )}
        </div>
        {openTable && (
          <Modal open={openTable} onCancel={() => setOpenTable(false)} width={1200} footer={null} centered>
            <Spin spinning={loading[importsActions.getDocumentReview.typePrefix]||false}>
              <div className="table-responsive">
                <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                  <div>
                    <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 600, color: '#202126' }}>Imported Data</div>
                    <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, color: '#757680' }}>
                      File name: {detail?.attachment?.metadata?.name}
                    </div>
                    <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, color: '#757680' }}>
                      Total records: {reviews.length}
                    </div>
                  </div>
                  {/* <IconX style={{ cursor: 'pointer' }} onClick={() => setOpenTable(false)} /> */}
                </div>
                  <TableWithScrollButtons
                    // style={{ padding: '0px 20px' }}
                    columns={subColumns}
                    dataSource={reviews}
                    size="small"
                    // pagination={false}
                    scroll={{ x: 800, y: 800 }}
                    onChange={(pagination, filter, sorter) => {
                      handleSortAndPaginationChangeSubData(pagination, sorter);
                    }}
                    pagination={{
                      showSizeChanger: true,
                      defaultCurrent: 1,
                      // total: paginationSubData.total,
                      position: ['bottomLeft'],
                      pageSizeOptions: pageSizeOptions,
                      showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                    }}
                  />
              </div>
            </Spin>
          </Modal>
        )}
        {isErrorModalOpen && (
          <Modal title="Error Data" open={isErrorModalOpen} onCancel={() => setIsErrorModalOpen(false)} width={1200} footer={null} centered>
            <Spin spinning={loading[importsActions.createDocument.typePrefix]||false}>
              <div className="table-responsive">
                <TableWithScrollButtons
                  // style={{ padding: '0px 20px' }}
                  columns={errorColumns}
                  dataSource={errorData}
                  size="small"
                  // pagination={false}
                  scroll={{ x: 800, y: 500 }}
                  // onChange={(pagination, filter, sorter) => {
                  //   handleSortAndPaginationChangeSubData(pagination, sorter);
                  // }}
                  pagination={{
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    // total: paginationSubData.total,
                    position: ['bottomLeft'],
                    pageSizeOptions: pageSizeOptions,
                    showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                  }}
                />
              </div>
            </Spin>
          </Modal>
        )}
        <div className="card-body">
          <div className="table-responsive">
            <TableWithScrollButtons
              columns={columns}
              dataSource={data}
              size="small"
              scroll={{ x: 800 }}
              onChange={(pagination, filter, sorter) => {
                handleSortAndPaginationChange(pagination, sorter);
              }}
              pagination={{
                showSizeChanger: true,
                defaultCurrent: 1,
                total: pagination.total,
                position: ['bottomLeft'],
                pageSizeOptions: pageSizeOptions,
                showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
              }}
            />
          </div>
        </div>
      </div>
      {isModalOpen && (
        <UploadFile
          open={isModalOpen}
          destroyOnClose={true}
          onCancel={() => setIsModalOpen(false)}
          onSubmit={handleSubmitDocument}
          confirmLoading={loading[importsActions?.createDocument.typePrefix]}
          countries={countries}
          hideDocumentType={true}
        />
      )}
    </div>
  );
};

export default Import;

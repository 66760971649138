import { common } from './common';

export const dev = {
  ...common,
  REACT_APP_BUILD_ENV: 'dev',
  BASE_URL: 'https://staging-eor-api.nxsys.tech',
  WEBSOCKET_BASE_URL: 'wss://chat.portal.com',
  STORE_URL: 'https://dy6pb5hiu8i7r.cloudfront.net',
  BUCKET_URL: 'https://eor-portal-dev.s3.eu-west-2.amazonaws.com',
};

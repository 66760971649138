import { IMessage, IMessageQuery } from '@/types/app';
import { Form, Modal, Radio, Select } from 'antd';
import type { ModalProps } from 'antd';
import { useSelector } from '@/stores';
import { constantsToOptions } from '@/utils';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
const MAX_FILE_SIZE = 50;

interface IProps extends ModalProps {
  data?: IMessage | null;
  onSubmit: (data: IMessageQuery) => void;
  status?: string;
}

const FilterMessageModal = (props: IProps) => {
  const {
    data,
    onSubmit,
    title = 'Filter',
    status = 'active',
    ...rest
  } = props;
  const [form] = Form.useForm();
  const { constants, countries } = useSelector((state) => state?.app);

  const { id } = useParams();


  const handleSubmitForm = (value: IMessageQuery) => {
    let newValue = { ...value };
    onSubmit(newValue);
    // form.resetFields();
  };

  useEffect(()=>{
    if(status && id){
      form.setFieldValue('status', status);
    } else {
      form.setFieldValue('status', 'active');
    }
  },[status])

  return (
      <Form className="mr-2" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} layout="vertical" onFinish={handleSubmitForm} form={form} preserve={false}>
        <div className="row">
          <div className={`${'col-lg-12'}`}>
            <Form.Item className="mb-0 border border-primary rounded px-2" name="status" rules={[{ required: true, message: 'This field is required!' }]}>
              {/* <Select
                  className="w-100"
                  placeholder="Select status"
                  options={constantsToOptions(constants?.chatRoomStatuses || [])}
                  defaultValue={'active'}
                ></Select> */}
              <Radio.Group className="d-flex align-items-center" defaultValue={status} onChange={form.submit}>
                {constants?.chatRoomStatuses.map((item) => (
                  <Radio className="" value={item.value}>
                    {item?.name} Conversation
                  </Radio>
                ))}
                {/* <Radio value={false}>No</Radio> */}
              </Radio.Group>
            </Form.Item>
          </div>
        </div>
      </Form>
  );
};

export default FilterMessageModal;

import { common } from './common';

export const prod = {
  ...common,
  REACT_APP_BUILD_ENV: 'prod',
  BASE_URL: 'https://api.portal.com',
  WEBSOCKET_BASE_URL: 'wss://chat.portal.com',
  STORE_URL: 'https://dy6pb5hiu8i7r.cloudfront.net',
  BUCKET_URL: 'https://eor-portal-dev.s3.eu-west-2.amazonaws.com',
};

import { IDashboardClient, IDashboardCountry, IUser } from '@/types/app';
import { Button, Form, Grid, Input, Popconfirm, Space, Spin, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { Link, useLocation } from 'react-router-dom';
import { EyeTwoTone, DeleteTwoTone, LoginOutlined, PlusOutlined } from '@ant-design/icons';
import { ROUTE_PATH } from '@/common/constants/routes';
import dayjs from 'dayjs';
import { dateFormat, dateTimeFormat, pageSizeOptions } from '@/common/constants/format';
import TableWithScrollButtons from '@/components/Shared/TableWithScrollButtons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from '@/stores';
import { userActions } from '@/stores/user';
import { authActions } from '@/stores/auth';
import { UserRole } from '@/common/constants/enums';
import { getBreakPoint, matchValueToLabel } from '@/utils';
import { isRootAdmin } from '@/utils/auth';
import { DeleteIcon, EditIcon, EyeIcon, GrayEditIcon, LoginAsIcon, SearchIcon } from '@/components/common/IconComponents';
import { dashboardActions } from '@/stores/dashboard';
import { SorterResult } from 'antd/es/table/interface';
import { endClientsActions } from '@/stores/endClients';

const { useBreakpoint } = Grid;

const DashboardAllEndClients = () => {
  const screens = getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('xl');
  const { constants } = useSelector((state) => state.app);
  const { userInfo } = useSelector((state) => state.auth);
  const { dashboardAllEndClients, loading } = useSelector((state) => state.dashboard);

  const location = useLocation();
  const search = new URLSearchParams(location?.search);
  const countryID = search?.get('countryID') || undefined;

  const columns: ColumnsType<IDashboardClient> = [
    {
      title: 'Actions',
      key: 'action',
      width: 100,
      fixed: 'left',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Link to={`${_.id}/update`}>
            <Tooltip title="Update">
              <Button type="text" size="small">
                <GrayEditIcon />
              </Button>
            </Tooltip>
          </Link>
          <Popconfirm
            title="Delete this employee"
            description="Are you sure to delete?"
            onConfirm={async () => {
              dispatch(
                endClientsActions.deleteEndClient({
                  id: record.id,
                }),
              )
                ?.unwrap()
                ?.then(() => {
                  dispatch(dashboardActions.getDashboardAllEndClients({}));
                });
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <Button type="text" size="small">
                <DeleteIcon />
              </Button>
            </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: 'Client Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      sorter: true,
      render: (_, record) => (
        <div>
          <b>{record?.name}</b>
          <p className="mb-0">{record.username ? '@' + record.username : ''}</p>
        </div>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      width: 250,
      sorter: true,
      render: (name: any, elm: IDashboardClient) =>
        elm?.country?.id ? (
          <Space>
            <img src={elm?.country?.flag} width="16" height="12" alt={`${elm?.country?.name}`} className="mr-2" />
            {elm?.country?.name}
          </Space>
        ) : null,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 150,
      sorter: true,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      width: 200,
      sorter: true,
    },
    {
      title: 'No. Employees',
      dataIndex: 'totalEmployees',
      key: 'totalEmployees',
      width: 200,
      sorter: true,
      render: (totalEmployees: number, elm: IDashboardClient) => (
        <Tag className="rounded">
          <Link to={`${ROUTE_PATH.EMPLOYEE_LIST}?endClientID=${elm?.id}`}>{totalEmployees}</Link>
        </Tag>
      ),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   width: 150,
    //   sorter: true,
    //   render: (value) => {
    //     return (
    //       <Tag color={value === 'active' ? 'success' : 'default'} className="text-uppercase">
    //         {value}
    //       </Tag>
    //     );
    //   },
    // },
  ];

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  const handleSearchSubmit = (e: string) => {
    const keyword = e.trim();
    setSearchText(keyword);
    dispatch(dashboardActions.getDashboardAllEndClients({ keyword, countryID }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(dashboardActions.getDashboardAllEndClients({ page, limit: pageSize, countryID }));
  };

  const handleSortAndPaginationChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<IDashboardClient> | SorterResult<IDashboardClient>[],
  ) => {
    dispatch(
      dashboardActions.getDashboardAllEndClients({
        page: pagination.current,
        limit: pagination.pageSize,
        keyword: searchText,
        sortBy: !Array.isArray(sorter) && sorter.order ? `${sorter.field} ${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` : undefined,
        countryID,
      }),
    );
  };


  // useEffect(() => {
  //   console.log('getDashboardAllEndClients');
  //   dispatch(dashboardActions.getDashboardAllEndClients({}));
  // }, []);

  useEffect(() => {
    // if (location.search.includes("?keyword=")) {
    //   setSearchText(keyword || "");
    //   handleSearchSubmit(keyword || "");
    // } else
    if (userInfo?.role !== UserRole.admin) {
      if (location.search && (countryID) ) {
        dispatch(dashboardActions.getDashboardAllEndClients({countryID}));
      } else dispatch(dashboardActions.getDashboardAllEndClients({}));
    }
  }, [location.search]);

  return (
    <div className="container-fluid">
      <Spin tip="Loading" size="large" spinning={loading[dashboardActions.getDashboardAllEndClients.typePrefix]}>
        <div className="d-flex justify-content-between py-3">
          <div className="page-listing-title d-flex justify-content-between align-items-center mb-0">
            <span>End Clients</span>
          </div>
          <div className="d-flex">
            <Link to={ROUTE_PATH.DASHBOARD_ALL_END_CLIENTS_CREATE}>
              <Button type="primary" className="d-flex align-items-center">
                <PlusOutlined /> Create New
              </Button>
            </Link>
          </div>
        </div>
        <div className="bg-white rounded">
          <div className="card-header">
            <Input
              prefix={<SearchIcon className="mr-0" />}
              placeholder="Search"
              onPressEnter={(e: any) => handleSearchSubmit(e?.target?.value)}
              className="w-100 mr-2 page-listing-search-input"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <TableWithScrollButtons
                columns={columns}
                dataSource={dashboardAllEndClients?.data}
                size="small"
                scroll={{ x: 1000 }}
                onChange={(pagination, filter, sorter) => {
                  handleSortAndPaginationChange(pagination, sorter);
                }}
                pagination={{
                  // onChange: (page: number, pageSize: number) => handlePaginationChange(page, pageSize),
                  showSizeChanger: true,
                  defaultCurrent: 1,
                  total: dashboardAllEndClients?.pagination?.total,
                  position: ['bottomLeft'],
                  pageSizeOptions: pageSizeOptions,
                  showTotal: (total: number, range: number[]) => 'Showing ' + range[0] + ' to ' + range[1] + ' of ' + total + ' entries',
                }}
              />
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

export default DashboardAllEndClients;

import React, { useEffect, useMemo } from "react";
import { Dropdown, Avatar } from "antd";
import Icon,{
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  FONT_WEIGHT,
  MEDIA_QUERIES,
  SPACER,
  FONT_SIZES,
} from "@/common/constants/ThemeConstant";
import { useDispatch, useSelector } from "@/stores";
import { appActions } from "@/stores/app";
import { matchValueToLabel } from "@/utils";
import { Link, useNavigate } from "react-router-dom";
import { isLoginAs } from "@/utils/auth";
import { authActions } from "@/stores/auth";
import { ROUTE_PATH } from "@/common/constants/routes";
import styled from "@emotion/styled";
import Flex from "./Flex";
import { ReactComponent as ArrowDownIcon } from '@/assets/images/new-icons/arrow-down.svg';
import NavItem from "./NavItem";


const CustomIcon = styled.div(() => ({
  fontSize: FONT_SIZES.LG,
}));

const Profile = styled.div(() => ({
  display: "flex",
  alignItems: "center",
}));

const UserInfo = styled("div")`
  padding-left: ${SPACER[2]};

  @media ${MEDIA_QUERIES.MOBILE} {
    display: none;
  }
`;

const Name = styled.div(() => ({
  fontWeight: FONT_WEIGHT.SEMIBOLD2,
  fontSize: '14px',
  lineHeight: '20px',
  color: '#000000',
}));

const Title = styled.span(() => ({
  fontWeight: FONT_WEIGHT.NORMAL,
  fontSize: '12px',
  lineHeight: '18px',
  opacity: 0.8,
  color: '#000000',
}));

const MenuItem = (props: any) => (
  <Link to={props?.path} onClick={() => props?.onClick?.()}>
    <Flex alignItems="center" gap={SPACER[2]}>
      <CustomIcon>{props.icon}</CustomIcon>
      <span>{props.label}</span>
    </Flex>
  </Link>
);

const MenuItemSignOut = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    // dispatch(authActions.onLogout());
  };

  return (
    <div onClick={handleSignOut}>
      <div>
          <LogoutOutlined />
        <span>{props.label}</span>
      </div>
    </div>
  );
};

export const NavProfile = ({ mode="dark", isMobile }: any) => {
  const { constants } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSignoutClick = () => {
    dispatch(authActions.onLogout());
    navigate("/login");
  };
  const items = useMemo(() => {
    return isLoginAs()
      ? [
          {
            key: 'Return To Admin',
            label: (
              <MenuItem
                // path={ROUTES.SETTINGS_ADMIN_USERS}
                label="Return To Admin"
                icon={<SettingOutlined />}
                onClick={() => dispatch(authActions?.backRootAccount())}
              />
            ),
          },
          {
            key: 'Profile',
            label: <MenuItem path={ROUTE_PATH.PERSONAL_INFO} label="Profile" icon={<EditOutlined />} />,
          },
          {
            key: 'Sign Out',
            label: <MenuItem label="Sign Out" icon={<LogoutOutlined />} onClick={handleSignoutClick} />,
          },
        ]
      : [
        {
          key: 'Profile',
          label: <MenuItem path={ROUTE_PATH.PERSONAL_INFO} label="Profile" icon={<EditOutlined />} />,
        },
        {
          key: 'Sign Out',
          label: <MenuItem label="Sign Out" icon={<LogoutOutlined />} onClick={handleSignoutClick} />,
        },
      ]
  }, [user]);

  return (
    <Dropdown className="header-profile" placement="bottomRight" menu={{ items }} trigger={['click']}>
      <NavItem mode={mode}>
        <Profile >
          {/* {user?.avatar?<Avatar src={user?.avatar?.fileURL}/>:<Avatar icon={<UserOutlined />} />} */}
          <Avatar size={32} icon={<UserOutlined />} />
          <UserInfo className="profile-text d-flex flex-column">
            <Name>{user?.name || user?.employee?.name}</Name>
            <Title>{user?.email || user?.employee?.email}</Title>
          </UserInfo>
        </Profile>
        {isMobile ? null : <Icon component={() => <ArrowDownIcon />} />}
      </NavItem>
    </Dropdown>
  );
};

export default NavProfile;

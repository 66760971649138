
import { dateFormat } from '@/common/constants/format';
import { useSelector } from '@/stores';
import { IEmployee } from '@/types/app';
import { matchValueToLabel } from '@/utils';
import { Card, Col, Row } from 'antd';
import dayjs from 'dayjs';
const OffboardingStatusDetail = (props: { detail?: IEmployee}) => {
  const { detail } = props;
  const { constants } = useSelector((state) => state.app);

  return (
    <Card className="mb-3">
      <Row gutter={[16, 8]} className="general-information">
        <Col xs={24} className="py-3 title-tab">
          <p className="title">Offboarding Status</p>
        </Col>
        <Col xs={8}>
          <p className="label">Status</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.offBoardingStatus ? (
            matchValueToLabel(constants?.offBoardingStatuses || [], detail?.offBoardingStatus)
          ) : (
            <p className="none">none</p>
          )}
        </Col>
        <Col xs={8}>
          <p className="label">Reason for Termination</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.reasonForTermination || <p className="none">none</p>}
        </Col>
        <Col xs={8}>
          <p className="label">Anticipated End Date</p>
        </Col>
        <Col xs={16} className="value">
          {detail?.anticipatedEndDate ? dayjs(detail?.anticipatedEndDate).format(dateFormat) : <p className="none">none</p>}
        </Col>
      </Row>
    </Card>
  );
};

export default OffboardingStatusDetail;

import React, { useState } from 'react';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadFile } from 'antd/es/upload/interface';
import { ReactComponent as UploadIcon } from '@/assets/images/new-icons/upload-icon.svg';

const { Dragger } = Upload;

// const CustomUpload = ({ fileList, setFileList , condition}: { fileList: UploadFile[]; setFileList: (value: UploadFile[]) => void }) => {
const CustomUpload = ({
  fileList,
  setFileList,
  condition,
}: {
  fileList: UploadFile[];
  setFileList: (value: UploadFile[]) => void;
  condition: any;
}) => {
  const handleChange = (info: any) => {
    let newFileList = [...info.fileList];
    // newFileList = newFileList.slice(-5);
    // setFileList(newFileList);

    newFileList.forEach((file) => {
      if (file.status === 'uploading') {
        setTimeout(() => {
          file.status = 'done';
          setFileList([...newFileList]);
        }, 1000);
      }
    });
  };

  const handleRemove = (file: UploadFile) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    message.info(`${file.name} has been removed.`);
  };

  return (
    <div id={condition === 'create'?'':"custom-upload"}>
      <Dragger
        id={condition === 'create'?'':"custom-upload"}
        maxCount={5}
        name="files"
        multiple
        fileList={fileList}
        onRemove={handleRemove}
        onChange={handleChange}
        customRequest={() => {}}
        style={{backgroundColor: '#0F4C851a' }}
      >
        {condition === 'create' ? (
          <div>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned files.
            </p>{' '}
          </div>
        ): <UploadIcon style={{ width: '50px'}} />}
      </Dragger>
    </div>
  );
};

export default CustomUpload;
